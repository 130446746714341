<template>
  <b-card
    v-b-modal.modal
    :img-src="imageProp || picturesProp[0]  "
    img-alt="The best app to monitor your open source project"
    img-top
    class="text-center"
    align="center"
    v-b-modal="modalId"
    no-body
  >
    <template v-slot:header>
      <p class="project-title">
        <strong>{{ projectTitleProp }}</strong>
      </p>
      

    </template>
    <template v-slot:footer>
      <!-- Mobile app badge-->
      <b-badge v-show="appTypeProp === 0" style="background-color: #2196F3; color: #f0f0f0">Mobile App</b-badge>
      <!-- Web app badge-->
      <b-badge v-show="appTypeProp === 1" style="background-color: #FFA500; color: #f0f0f0">Web App</b-badge>
      <!-- Api badge-->
      <b-badge v-show="appTypeProp === 2" style="background-color: #993299; color: #f0f0f0">Package</b-badge>
    </template>
    <!-- The modal which appears when we click on "see more" -->
      <b-modal size="lg" :id="modalId" scrollable hide-footer>
        <template v-slot:modal-title>
          <!-- Modal title -->
          <p class="project-title">
            <strong>{{ projectTitleProp }}</strong>
          </p>
        </template>
        <!-- Description -->
        <div class="d-block" style="color: #1a1a1a !important;">
          <b-row>
            <b-carousel
              id="carousel-1"
              class="carousel"
              v-model="slide"
              :interval="14000"
              controls
              fade
              indicators
              img-height="910px"
              img-width="1918px"
              background="#ababab"
            >
              <b-carousel-slide
                :style="picStyle"
                v-for="(pic, index) in picturesProp"
                :key="index"
                :img-src="pic"
              ></b-carousel-slide>
            </b-carousel>
          </b-row>
          
          <!-- Description -->
          <b-row style="margin: auto; color: #1a1a1a !important;  margin-top: 50px;" v-html="contentDescription" />
          <b-row>
            <b-button
              v-show="urlProp"
              v-b-modal="modalId"
              style="background-color: #1a1a1a; border-radius: 15px; margin: auto "
              @click="gotToUrl"
            >Checkout the source code</b-button>
          </b-row>
        </div>
      </b-modal>
      <!-- <b-button v-b-modal="modalId" style="background-color: #1a1a1a; border-radius: 15px;">See more</b-button> -->
      
  </b-card>
</template>
<script>
import marked from "marked";
export default {
  props: [
    "projectTitle",
    "image",
    "id",
    "pictures",
    "description",
    "picPadding",
    "appType",
    "url",
  ],
  created: function () {
      this.getDescription(this.id);
  },
  data: function () {

    return {
      projectTitleProp: this.projectTitle,
      urlProp: this.url,
      imageProp: this.image || this.pictures[0] ? this.image : "/img/defaultProject.png",
      idProp: this.id,
      picturesProp: this.pictures,
      modalId: "modal-center-" + this.id,
      sliding: true,
      slide: 0,
      appTypeProp: this.appType,
      contentDescription: null,
      picStyle: {
        Padding: this.picPadding,
      },
    };
  },
  methods: {
    gotToUrl() {
      // Open the url in anorther tab
      window.open(this.urlProp, "_blank");
    },
    toggleAutoSwap() {
      this.sliding = true;
    },
    unToggleAutoSwap() {
      this.sliding = false;
    },
    async getDescription(id) {
      this.contentDescription = "rendering...";
          await this.$http.get("./projectDescriptions/"+id+".md")
                    .then(response => {
                      this.contentDescription = marked(response.body);
                      });
    }
  },
};
</script>
<style scoped>
p {
  color: aqua;
}
.card {
  max-width: 220px;
  min-width: 220px;
  margin: auto;
  margin-top: 20px;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  /* margin: auto !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important; */
}
.card-header {
  height: 80px;
}
.card-footer {
  height: 35px;
  padding: 3px;
}
.card-img-top{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px ;
  height: 150px;
}
.card {
  border-radius: 15px;
}
.project-title {
  color: #1a1a1a;
}
.card-img-top {
  max-height: 150px;
  margin: auto;
}
.carousel {
  margin-top: -26px;
}
@media screen and (max-width: 600px) {
  .card {
  max-width: 170px;
  min-width: 170px;
  min-height: 225px;
  max-height: 225px;
}
.card-header {
  height: 65px;
}
.card-img-top{
  height: 120px;
}
}
@media screen and (max-width: 400px) {
  .card {
  max-width: 150px;
  min-width: 150px;
  min-height: 200px;
  max-height: 200px;
}
.card-header {
  height: 65px;
}
.card-img-top{
  height: 100px;
}
}
</style>