<template>
<div id="Contributions">
    <b-container >
        <!-- Title -->
        <h1 class="title masthead-heading section-title" >Open source Contributions</h1>
        <hr class="my-4" />
        <!-- List of contribution -->
        
        <b-card-group columns>
            <!-- GitTrends contribution card -->
            <ContributionCard contributionTitle="GitTrends" 
                              url="https://github.com/brminnick/GitTrends"
                              image="https://user-images.githubusercontent.com/13558917/85167001-fc4db180-b21c-11ea-9055-bddb158542a7.gif"  />
            <!-- Custard contribution card -->
            <ContributionCard contributionTitle="Custard Api" 
                              url="https://github.com/bricefriha/CustardApi"
                              image="https://user-images.githubusercontent.com/37577669/85275198-47b3ca00-b480-11ea-8273-d990295416a7.png"  />
            
            
            

        </b-card-group>
        
    </b-container>
</div>
</template>
<script>
import ContributionCard from '../components/ContributionCard.vue';
export default {
    components: {
        ContributionCard,
    }
}
</script>
<style scoped>
    /* .container {
        margin: auto !important;
        
        
    } */
    .card-columns {
        display: flex !important;
        flex-flow: row wrap !important;
        margin: auto !important;
    }
    .title {
        
        color: #1a1a1a;
    }
</style>