<template>
  <footer class="footer text-center">
    <div class="container" id="Contact">
      <b-row>
        <!-- Footer Social Icons -->
        <div class="col-lg-4  mb-lg-0" style="margin: auto;">
          <h4 class="text-uppercase mb-4 label">Get in touch with me! 😉</h4>
          <!-- <Social :faIcon="['fab', 'twitter']" url="https://twitter.com/BriceFriha" />  -->
          <a
            class="btn btn-outline-light btn-social mx-1 social"
            @click="gotToUrl('https://twitter.com/BriceFriha')"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <a
            class="btn btn-outline-light btn-social mx-1 social"
            @click="gotToUrl('https://github.com/bricefriha')"
          >
            <font-awesome-icon :icon="['fab', 'github']" />
          </a>
          <a
            class="btn btn-outline-light btn-social mx-1 social"
            @click="gotToUrl('https://www.linkedin.com/in/brice-friha/')"
          >
            <font-awesome-icon :icon="['fab', 'linkedin']" />
          </a>
        </div>
      </b-row>
      <b-row >
        <div  style="margin: auto;">
          <h5 class="label">You could send me an email at </h5>
          <a 
              v-b-popover.Top="{ variant: 'success',  content: 'The email is copied'}"
              v-clipboard:copy="message"
              class="btn btn-outline-light btn-social mx-1 social"
            >contact@bricefriha.dev</a>
        </div>
      </b-row>
      <b-row>
        
      </b-row>
    </div>
  </footer>
</template>
<script>
// import Social from '../components/Socials.vue';
export default {
  components: {
    // Social,
  },
  data: function () {
      return {
        message: 'contact@bricefriha.dev'
      }
    },
  methods: {
    // Function to go to a url
    gotToUrl(url) {
      // Open the url in anorther tab
      window.open(url, "_blank");
    },
    onCopy: function (e) {
      alert('You just copied: ' + e.text)
    },
    onError: function () {
      alert('Failed to copy texts')
    }
  },
};
</script>
<style>
.footer {
  background-color: #1a1a1a;
  padding: 10px;
  margin-top: 0px;
}
.label {
  color: whitesmoke;
}
.social {
  color: whitesmoke !important;
}
.row {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>