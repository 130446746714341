<template>
    <div id="Skills">
      <b-container >
          <!-- Title -->
          <h1 class="title masthead-heading" >Skills</h1>
          <hr class="my-4">

          <!-- Languages -->
          <h3 :style="sectionSubtitleStyle" >Programming Languages</h3>

          <ul class="list-inline dev-icons" >
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'C#'"  v-bind="icoTech" fluid src="https://styles.redditmedia.com/t5_2qhdf/styles/communityIcon_sk8k2hisvyv51.png"></b-img-lazy>
            </li>
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'JavaScript'" v-bind="icoTech" fluid src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png"></b-img-lazy>
            </li>
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'TypeScript'" v-bind="icoTech" fluid src="https://i0.wp.com/blog.js-republic.com/wp-content/uploads/2016/10/TypeScript.png"></b-img-lazy>
            </li>
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'Java'" v-bind="icoTech" fluid src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/800px-Java_programming_language_logo.svg.png"></b-img-lazy>
            </li>
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'Python'" v-bind="icoTech" fluid src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1200px-Python-logo-notext.svg.png"></b-img-lazy>
            </li>
          </ul>

          <!-- Frameworks -->
          <h3 :style="sectionSubtitleStyle" >Frameworks</h3>

          
          <ul class="list-inline dev-icons">
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'Xamarin'" v-bind="icoTech" fluid src="https://res.cloudinary.com/crunchbase-production/image/upload/v1502742226/p7opycvzczgkbqtdeue4.png"></b-img-lazy>
            </li>
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'.NET Core'" v-bind="icoTech" fluid src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/.NET_Core_Logo.svg/1200px-.NET_Core_Logo.svg.png"></b-img-lazy>
            </li>
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'VueJs'" v-bind="icoTech" fluid src="/img/logo.png"></b-img-lazy>
            </li>
            <li class="list-inline-item">
              <b-img-lazy v-b-popover.hover.bottom="'NodeJs'" v-bind="icoTech" fluid src="https://www.netgains.org/wp-content/uploads/2014/01/node_js.png"></b-img-lazy>
            </li>
          </ul>
        </b-container >
    </div>
</template>
<script>
export default {
    data(){
    return{
      sectionTitleStyle: {
          center: true,
          color: '#1a1a1a',
          class: 'masthead-heading'
        },
    sectionSubtitleStyle: {
        center: true,
        color: '#1a1a1a',
        class: 'masthead-heading',
        margin: '5% 0% 2% 0%'
        
    },
      icoTech: {
          width: 80,
          height: 80,
        },
    }
  }
}
</script>
<style>
li{
  margin: 0px 30px;
}
h4{
  margin: 20px 0px;  
}
@media screen and (max-width: 600px) {
  .list-inline-item {
    width: 18%;
    height: 18%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sectionSubtitleStyle {
    margin-top: 100% !important;
  }
}
.title {
        color: #1a1a1a;
    }
</style>