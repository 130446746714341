<template>
  <div id="app">
    
    <!-- Import the Navbar--> 
    <NavBar />
    <Intro  />
    <AboutMe class="section-block"  />
    <!-- Portfolio section -->
    <Portfolio class="section-block"  />
    <!-- contribution section -->
    <Contribution class="section-block" :navHeight="navHeightComp" :titleSize="titleSizeComp"  />
    <!-- Skills section -->
    <Skill class="section-block"  />
    <!-- Footer which is also the contact section-->
    <Footer />
    <!-- Copyright-->
    <section class="copyright py-4 text-center text-white">
    <div class="container">
      <small>Copyright © Brice Friha 2021</small>
    </div>
  </section>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Intro from './components/Intro.vue'
import Footer from './components/Footer.vue'
import Skill from './components/Skill.vue'
//import Domain from './components/Domain.vue';
import Contribution from './components/Contribution.vue';
import Portfolio from './components/Portfolio.vue';
import AboutMe from './components/AboutMe.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    Intro,
    Footer,
    Skill,
    Contribution,
    Portfolio,
    AboutMe,
  },
  methods:{
    handleScroll(){
      if (
          document.body.scrollTop > 45 ||
          document.documentElement.scrollTop > 45
        ) {
          // document.getElementById("navbar").style.padding = "20px 10px";
          //$(".navbar").style.height = "70px";
          this.navHeight =  70;
          this.titleSize = 20;
        } else {
          // document.getElementById("navbar").style.paddingBottom = "10px";
          // document.getElementById("navbar").style.paddingTop = "35px";
          //$(".navbar").style.height = "85px";
          this.navHeight = 85;
          //this.$set(this.navHeight, 85);
          this.titleSize = 25;
        }
    }
  },
  computed:{
    navHeightComp(){
      return this.navHeight + "px"
    },
    titleSizeComp(){
      return this.titleSize + "px"
    }
  },
  data(){
    return{
      sectionTitleStyle: {
          center: true,
          color: '#1a1a1a',
          class: 'masthead-heading'
        },
      sectionSubtitleStyle: {
          center: true,
          color: '#1a1a1a',
          class: 'masthead-heading'
          
      },
      sectionStyle: {
          margin: 10
          
      },
      titleSize: 25,

    }
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: public-sans_reg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 85px;
  
  background-color: black;
  
}
@font-face {
font-family: 'public-sans_reg';
src: url('../public/fonts/public-sans.regular.otf');

    }
h1, h2,h3,h4 {
  font-family: public-sans_bold;
  
}
@font-face {
font-family: 'public-sans_extrabold';
src: url('../public/fonts/public-sans.extrabold.otf');

}
@font-face {
font-family: 'public-sans_bold';
src: url('../public/fonts/public-sans.bold.otf');

}
section{
  background-color: black;
}
.section-block {
  padding-bottom: 30px ;
  
  padding-top: 70px;
  background-color: white;
}
@media screen and (max-width: 600px) {
  .section-block {
    padding-top: 60px;
  }
  #app {
    padding-top: 85px;
  }
  .section-title {
    font-size: 32px!important ;
  }
}
</style>
