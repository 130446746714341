<template>
    <b-card v-b-modal.modal :img-src="imageProp"
            img-alt="The best app to monitor your open source project"
            img-top  
            class="text-center "
            align="center"
            no-body>
            <template v-slot:header>
                <h4 class="contribution-title" ><strong> {{ contributionTitleProp }} </strong></h4>
            </template>
            <template v-slot:footer>
                <b-button class="btnMore" @click="gotToUrl" style="background-color: #1a1a1a; border-radius: 15px;">See more</b-button>
            </template>

    </b-card>
</template>
<script>
export default {
    props: ['contributionTitle', 'url', 'image'],
    data: function () {
        return {
            contributionTitleProp: this.contributionTitle,
            urlProp: this.url,
            imageProp: this.image,
        };
    },
    methods: {
        gotToUrl() {
            // Open the url in anorther tab
            window.open(this.urlProp, '_blank');
        }
    }
}
</script>
<style scoped>
    .card {
        max-width: 200Px;
        min-width: 200Px;
        max-height: 500Px;
        margin: auto ;
        margin-top: 20px ;
        box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
        /* margin: auto !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important; */
    }
     .card-img-top, .card {
        border-radius: 15px;
        
    }
    .contribution-title {
        color: #1a1a1a;
    }
    .card-img-top {
        max-height: 150Px;
    }
    @media screen and (max-width: 600px) {
  .card {
  max-width: 170px;
  min-width: 170px;
  min-height: 255px;
  max-height: 255px;
}
.card-header {
  min-height: 75px;
  max-height: 75px;
  padding: 10px;
}
.card-footer {
  height: 60px;
}
.card-img-top{
  height: 120px;
}
.btnMore{
    height: 35px;
    padding: 3px 10px;
}
}
@media screen and (max-width: 400px) {
  .card {
  max-width: 150px;
  min-width: 150px;
  min-height: 230px;
  max-height: 230px;
}
.card-img-top{
  height: 100px;
}
}
</style>