<template>
<div id="Portfolio">
    <b-container >
        <!-- Title -->
        <h1 class="title masthead-heading section-title" >Works & Projects</h1>
        <hr class="my-4" />

        <!-- List of projects -->
        <b-card-group columns>
            <!-- Go through every Projects--> 
            <PortfolioCard v-for="(project, index) in this.projects" :key="index" 
                           :projectTitle="project.title" 
                           :id="project.id"
                           :url="project.url"
                           :image="project.image"
                           :pictures="project.pictures"
                           :description="project.description"
                           :appType="project.appType"  />
            

        </b-card-group>
        
    </b-container>
    </div>
</template>
<script>
import PortfolioCard from '../components/PortfolioCard.vue';

// Enums for app type 
const AppType = Object.freeze({ mobile: 0, web: 1, package: 2}) 
export default {
    components: {
        PortfolioCard,
    },
    data: function (){
        return {
            
            projects: {
                wikeasy: {
                    id: 'wikeasy',
                    title: 'Wikeasy',
                    pictures: [
                        '/img/wikeasy/WikeasyScreen1.png',
                        '/img/wikeasy/WikeasyScreen2.png',
                        '/img/wikeasy/WikeasyScreen3.png',
                        '/img/wikeasy/WikeasyScreen4.png',
                        // '/img/wikeasy/WikeasyScreen.gif',
                    ],
                    url: '',
                    imgWidth: "50px",
                    appType: AppType.mobile
                },
                piggy: {
                    id: 'piggy',
                    title: 'Blue Piggy',
                    pictures: [
                        '/img/piggy/Budgetspage1.png',
                        '/img/piggy/Setpage1.png',
                        '/img/piggy/signinpage1.png',
                    ],
                    url: '',
                    imgWidth: "50px",
                    appType: AppType.mobile
                },
                todoKernelMobileApp: {
                    id: 'todokernel_mobile',
                    title: 'TodoKernel Mobile App',
                    pictures: [
                        "/img/todoKernelMobileApp/TodolistPage.png",
                        "/img/todoKernelMobileApp/TodoKernelSignin.png"
                    ],
                    url: 'https://github.com/bricefriha/TodoKernel_mobile',
                    appType: AppType.mobile,
                    picPadding: "100%"
                },
                // custard: {
                //     id: 'custard',
                //     title: 'Custard Api',
                //     pictures: [
                //     ],
                //     image: 'https://user-images.githubusercontent.com/37577669/85275198-47b3ca00-b480-11ea-8273-d990295416a7.png',
                //     url: 'https://github.com/bricefriha/CustardApi',
                //     appType: AppType.package
                // },
                
                TodoKernelApi: {
                    id: 'todoKernel_api',
                    title: 'TodoKernel',
                    pictures: [
                    ],
                    
                    url: 'https://github.com/bricefriha/TodoKernel',
                    appType: AppType.web
                },
                // TodoKernelWeb: {
                //     id: 'todoKernel_webapp',
                //     title: 'TodoKernel WebApp',
                //     pictures: [
                //         "/img/todoKernelWebApp/dashboard.PNG",
                //         "/img/todoKernelWebApp/login.PNG"
                //     ],
                //     url: 'https://github.com/bricefriha/TodoKernel_webapp_sample',
                //     appType: AppType.web,
                //     picPadding: "100%"
                // },
                

            }
        }
    }
}
</script>
<style scoped>
    .card-columns {
        display: flex !important;
        flex-flow: row wrap !important;
        margin: auto !important;
    }
    .title {
        
        color: #1a1a1a;
    }
</style>