<template>
  <b-navbar id="navbar" toggleable="lg" :style="{ height: navCompHeight}" class="transparent fixed-top navbar-custom" type="dark" v-scroll="handleScroll">
    <b-navbar-brand
      tag="h1"
      class="mb-0"
      @click="navigateToTop()"
      style="margin-left: 20px; fontSize:25px;"
      id="title"
    >BRICE FRIHA</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" style="background-color: #1a1a1a; " >
      
      <b-navbar-nav class="ml-auto">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </b-navbar-nav>
    </b-navbar-toggle>


    <b-collapse id="nav-collapse" is-nav style="background-color: #1a1a1a; ">
      <b-navbar-nav class="ml-auto" id="nav">
        <b-nav-item @click="navigateTo ('AboutMe')" right>About me</b-nav-item>
        <b-nav-item @click="navigateTo ('Portfolio')" right>Works & Projects</b-nav-item>
        <b-nav-item @click="navigateTo ('Contributions')" right>Contributions</b-nav-item>
        <b-nav-item @click="navigateTo ('Skills')" right>Skills</b-nav-item>
        <b-nav-item @click="navigateTo ('Contact')" right>Contact</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  <!-- </nav> -->
</template>
<script>
export default {
  computed:{
    navCompHeight () {
      return this.navHeight + 'px';
    }
  },
  methods: {
    // Navigate to a section
    navigateTo(id) {
      // Smouth davigation
      window.scrollTo({
        top: document.getElementById(id).offsetTop,
        behavior: "smooth",
        inline: "center",
      });
    },
    handleScroll() {
      if (
          document.body.scrollTop > 45 ||
          document.documentElement.scrollTop > 45
        ) {
          this.navHeight =  70;
          document.getElementById("title").style.fontSize = "20px";
        } else {
          this.navHeight = 85;
          document.getElementById("title").style.fontSize = "25px";
        }
    },
    changeHeight(height) {
      this.navHeight = height;
    },
    // Navigate to top
    navigateToTop() {
      // Smouth davigation
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        inline: "center",
      });
    },
  },
  data() {
    return {
      navHeight : 85,
    }
  },
};
</script>
<style>
.nav-item {
  font-size: 20px;
}
.navbar {
  
  opacity: 0.93;
  background-color: #1a1a1a;
  font-size: 25px;
}

#title {
  font-size: 25px ;
}
</style>