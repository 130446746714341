<template>
<div id="Intro">
  <!-- <div v-bind="introDiv" style=" background-image: url('/img/grayscale-photo-of-computer-laptop-near-white-notebook-and-169573.jpg'); 
          background-position: bottom; background-size: 100%;"> -->
          
      <b-jumbotron style=" background-image: url('/img/grayscale-photo-of-computer-laptop-near-white-notebook-and-169573.jpg'); background-repeat: no-repeat; background-position: bottom; background-size: 100%; height: 100%">
          
          <template v-slot:header>
            <!-- Masthead Avatar Image -->
          <b-img-lazy v-bind="profilImage"  fluid src="/img/BriceFriha.jpeg"  rounded="circle" alt="Circle center image"></b-img-lazy>
          </template>

          <template v-slot:lead >
            <!-- Masthead Heading -->
            <h1 v-bind="heading" :style="heading" >Brice Friha</h1>
            <!-- Icon Divider -->

            <!-- Masthead Subheading -->
            <p v-bind="subheading" :style="subheading" style="font-weight: bold;" >💻 Freelancer | Xamarin mobile Development </p>
          </template>
          
      </b-jumbotron>
</div>
      
</template>
<script>

export default {
    data() {
      return {
        profilImage: {
          center: true,
          blankColor: '#1a1a1a',
          width: "270%",
          height: "270%",
          class: 'masthead-avatar mb-5 profilepic'
        },
        introDiv: {
          center: true,
          blankColor: '#1a1a1a',
        },
        heading: {
          center: true,
          color: '#F0F8FF',
          class: 'masthead-heading'
        },
        subheading: {
          center: true,
          color: '#F0F8FF',
          class: 'masthead-subheading',
          padding: 100
        },
      }
    },
}
</script>
<style scoped>
  .lead {
    background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.7); /* Black w/opacity/see-through */
  padding: 30px;
  width: 100%;
  margin: auto;
  }
  #Intro {
    
    margin-top:0px ;
    padding-top:0px ;
  }
  .jumbotron {
    padding: 0px ;
    margin-bottom: 0px ;
    
  }
  .display-3 {
    padding-top: 10px;
  }
  @media screen and (max-width: 600px) {
  .profilepic {
    width: 35%;
    height: 35%;
    margin-bottom: 0px !important ;
  }
  .lead {
    height: 105px;
    width: 100%;
    padding-top: 10px ;
    font-size: 15px ;
  }
  .display-3 {
    padding-top: 5px;
    margin-bottom: 4px;
  }
  
}
</style>