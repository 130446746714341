import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { NavbarPlugin } from 'bootstrap-vue'
import { ImagePlugin } from 'bootstrap-vue'
import { JumbotronPlugin } from 'bootstrap-vue'
import { CardPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import VueResource from "vue-resource";
 
library.add(fab);
library.add(fas);
 
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(CardPlugin)
Vue.use(JumbotronPlugin)
Vue.use(ImagePlugin);
Vue.use(NavbarPlugin)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true; // add this lines
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.use(VueResource);

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

new Vue({
  render: h => h(App),
}).$mount('#app')
